.footer-container {
    width: 100%;
    margin-top: auto;
}

.footer-row {
    margin: 0%;
}

.footer-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
}

.footer-content {
    display: flex;
    flex-direction: column;
}

.footer-lower {
    color: white;
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-heading {
    font-size: 24px;
}

.footer-col a {
    text-decoration: none;
    color: inherit;
}

.footer-logo {
    width: 180px;
    height: 180px;
    border-radius: 50%;
}