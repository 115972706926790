.profilelink-row {
    display: flex;
    justify-content: space-evenly;
    margin: 0%;
}

.profilelink-row a {
    text-decoration: none;
    color: inherit;
}

.profilelink-col {
    width: min-content;
    padding: 0%;
    margin-bottom: 30px;
}

.profilelink-box {
    margin: 10px;
    height: 300px;
    width: 300px;
    border: 2px solid rgb(150, 188, 223);
    border-radius: 10px;
    display: flex;
    flex-direction:  column;
    align-items: center;
    justify-content: space-evenly;
    text-decoration: none;
    color: inherit;
}

.profilelink-box-icon {
    font-size: 45px;
}

.profilelink-box:hover{
    cursor: pointer;
}