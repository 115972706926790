.mainmenu-container {
  width: 100%;
}

.mainmenu-content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-left: 8%;
  padding-right: 8%;
  padding-top: 3%;
}

.mainmenu-container__item {
  margin-right: 10px;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .mainmenu-content {
    display: flex;
    justify-content: center;
    padding: 0%;
  }
  .mainmenu-container__item {
    margin-right: 0%;
    margin-bottom: 25px;
    border: 1px solid rgb(21, 21, 21);
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgb(73, 72, 72);

  }
}

