.signup__row {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 20px;
}

.signup__bg {
  background-image: url(https://images.unsplash.com/photo-1615870593187-bd505110bd59?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80);
  height: 500px;
  width: 500px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow-x: hidden;
}

.login-link {
  text-decoration: none;
  color: black;
}

.signup-div {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 70%;
  max-width: 450px;
}

@media (max-width: 768px) {
  .signup__bg {
    display: none;
  }
}

@media (max-width: 992px) {
  .signup__bg {
    margin-top: 5%;
  }
}



  