.suggestion-component {
  margin-bottom: 30px;
}
.suggestion-component a{
  text-decoration:none;
  color: inherit;
}

.search-suggestion-box {
  position: absolute;
  width: 330px;
  max-height: 600px;
  background-color: white;
  padding: 15px;
  margin-top: 15px;
  overflow: hidden;
  overflow-y: auto;
  border-radius: 5px;
  box-shadow: 0px 0px 3px;
  z-index: 1;
}


.suggestion-component__title{
    font-size: 14px;
    color: gray;
    margin: 0%;
  }

  .suggestion-component__content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 90px;
    padding: 10px;
    overflow: hidden;
    border-radius: 5px;
    border: 1px solid rgb(184, 182, 182);
    margin-bottom: 5px;
  }
  .suggestion-component__img {
    width: 80px;
    height: 80px;
    object-fit: cover;
  }
  .suggestion-component__content:hover{
    background-color: #e7f1ff;
    cursor: pointer;
  }

  .category-suggestion-component__content{
    display: flex;
    align-items: center;
    height: 50px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid rgb(184, 182, 182);
    margin-bottom: 5px;
  }
.category-suggestion-component__content:hover{
    background-color: #e7f1ff;
    cursor: pointer;
  }





