.notFound-container {
    width: 100%;
    margin-bottom: 5%;
    height: 70vh;
}
.notFound-container * {
    margin: 0%;
    padding: 0%;
}
.notFound-container .row {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.notFound-container .row * {
    padding: 5px;
}

.notFound-gif {
    width: 100%;
}
.notFound-content {
    text-align: center  ;
}
.notFound-content h1 {
    font-size: 52px;
}
.notFound-content p {
    font-size: 18px;
}
.notFound-content * {
    margin-bottom: 50px;
}