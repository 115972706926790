.favorites-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1% 5%;
}

.favorites-content {
    display: flex;
    flex-wrap: wrap;
    border: 2px solid black;
    border-radius: 10px;
    width: 100%;
    padding: 3% 5%;
}
.favorites-product{
    margin-right: 10px;
    margin-bottom: 10px;
}

@media (max-width: 768px) { 
    .favorites-content{
        justify-content: center;
      }
    .favorites-product {
        margin-right: 0%;
    }
}